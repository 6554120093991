import { FC } from 'react';
import { ThemeProvider } from 'styled-components';
import { MuiThemeProvider } from '@material-ui/core';
import { StylesProvider } from '@material-ui/styles';
import { theme, GlobalStyles } from '../styles/';

const Styles: FC = ({ children }) => (
  <StylesProvider injectFirst>
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <>
          <GlobalStyles />
          {children}
        </>
      </ThemeProvider>
    </MuiThemeProvider>
  </StylesProvider>
);

export { Styles };
