import React from 'react';
import { CommonLayout } from '../components/layouts';
import { RebalanceForm } from '../components/forms';

const RebalanceFormPage = () => (
  <CommonLayout>
    <RebalanceForm />
  </CommonLayout>
);

export { RebalanceFormPage };
