import React from 'react';
import styled from 'styled-components';
import { omit } from 'lodash-es';
import { Formik, Form } from 'formik';
import { Grid } from '@material-ui/core';
import encode from 'form-urlencoded';
import {
  FormValues,
  useGetKrakenItems,
  useValidationSchema,
} from '../../hooks';
import { FormikInput } from '../formik-elements';
import { Spinner } from '../Spinner';
import { Button } from '../buttons';
import { getRabbitMQMessage, sendRebalanceMessage } from '../../utils/rabbitmq';
import { useSnackbar } from 'notistack';
import { Text, TextProps } from '../texts';

const RebalanceForm = () => {
  const { enqueueSnackbar } = useSnackbar();
  const schema = useValidationSchema('rebalanceMessage');
  const { loading, krakenNames } = useGetKrakenItems();

  if (loading) {
    return <Spinner />;
  }

  return (
    <Formik<FormValues['rebalanceMessage']>
      initialValues={{
        userId: 0,
        ...krakenNames.reduce(
          (acc, key) => ({
            ...acc,
            [key]: 0,
          }),
          {},
        ),
      }}
      validationSchema={schema}
      onSubmit={async (values, { resetForm }) => {
        try {
          const msg = getRabbitMQMessage(values);
          const encodedMessage = encode(msg);
          const result = await sendRebalanceMessage(encodedMessage);

          if (result.status !== 200) {
            throw new Error(`Status not 200, it's: ${result.status}`);
          }

          enqueueSnackbar(`Sent successfully`, { variant: 'success' });
          resetForm();
        } catch (e) {
          console.log(`Couldn't send message`, e);
          enqueueSnackbar(`Couldn't send message`, { variant: 'error' });
        }
      }}
    >
      {({ values, isSubmitting }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Header>User data</Header>
            </Grid>
            <Grid item xs={12}>
              <Input
                name={'userId'}
                label={'User ID'}
                type={'number'}
                style={{
                  marginBottom: 20,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Header>Exchange data</Header>
            </Grid>
            {Object.keys(omit(values, 'userId')).map((key) => (
              <Grid key={key} item xs={12} sm={6}>
                <Input name={key} label={key} type={'number'} />
              </Grid>
            ))}
          </Grid>

          <SubmitButton type={'submit'} fullWidth loading={isSubmitting}>
            Send
          </SubmitButton>
        </Form>
      )}
    </Formik>
  );
};

const Header = styled((props: TextProps) => (
  <Text component={'h2'} variant={'h4'} {...props} />
))``;

const Input = styled(FormikInput)`
  margin-top: 10px;
`;

const SubmitButton = styled(Button)`
  margin-top: 20px;
`;

export { RebalanceForm };
