import 'fontsource-roboto';
import { createGlobalStyle } from 'styled-components';
import { defaultFontSize } from './consts';

const GlobalStyles = createGlobalStyle`
  html, body {
    max-width: 100%;
    background-color: ${({
      theme: {
        palette: { background },
      },
    }) => background.default};
    overflow-x: hidden;
    font-size: ${defaultFontSize}px;
    font-weight: 700;
    font-style: italic;
    font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    line-height: 1.14;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: ${({ theme }) => theme.palette.text.primary};
  }

  *, *:before, *:after {
    box-sizing: border-box;
  }

  #root {
    min-height: 100vh;
  }

  img {
    display: block;
    max-width: 100%;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  p {
    margin: 0;
  }
`;

export { GlobalStyles };
