import React from 'react';
import { isString } from 'lodash-es';
import { useField, useFormikContext } from 'formik';
import { TextField, TextFieldProps } from '@material-ui/core';

export type FormikInputProps = TextFieldProps & {
  name: string;
};

const FormikInput = ({ name, ...props }: FormikInputProps) => {
  const { setFieldValue } = useFormikContext();
  const [{ onChange, ...field }, { touched, error }] = useField(name);

  return (
    <TextField
      {...field}
      onChange={(newValue) => {
        isString(newValue) ? setFieldValue(name, newValue) : onChange(newValue);
      }}
      {...props}
      {...(touched && !!error
        ? {
            error: true,
            helperText: error,
          }
        : {})}
    />
  );
};

export { FormikInput };
