import { omit } from 'lodash-es';
import { FormValues } from './validation-schemas';
import { callApi } from './api';

export const getRabbitMQMessage = (
  formValues: FormValues['rebalanceMessage'],
) => {
  const { userId } = formValues;
  const otherValues = omit(formValues, 'userId');
  const balances = Object.entries(otherValues).map(([key, value]) => ({
    Symbol: key,
    Percent: value,
  }));

  return {
    balances,
    rebalance_period: Date.now(),
    reason: 'SCHEDULED',
    user_id: userId,
  };
};

export const sendRebalanceMessage = (data: string) =>
  callApi('postBalancesMessage', data);
