import React, { FC } from 'react';
import styled from 'styled-components';
import { Container, Grid, Paper } from '@material-ui/core';

const CommonLayout: FC = ({ children }) => (
  <Container>
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Content>{children}</Content>
      </Grid>
    </Grid>
  </Container>
);

const Content = styled(Paper)`
  padding: 30px 20px;
  margin: 20px 0;
`;

export { CommonLayout };
