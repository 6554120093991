import * as Yup from 'yup';
import { omit } from 'lodash-es';

export type FormValues = Readonly<{
  rebalanceMessage: {
    [key: string]: number;
    userId: number;
  };
}>;

export type SchemasNames = keyof FormValues;

export const getSchemas: () => {
  [key in SchemasNames]: any;
} = () => ({
  rebalanceMessage: Yup.lazy((value) => {
    if (value !== undefined) {
      // @ts-ignore
      return Yup.object().shape<FormValues['rebalanceMessage']>({
        ...Object.keys(value).reduce(
          (acc, key) => ({
            ...acc,
            [key]: Yup.number()
              .min(0, 'Min value is 0')
              .max(100, 'Max value is 100')
              .default(0)
              .test('max', 'Total should not exceed 100 %', function (value) {
                return (
                  value === 0 ||
                  // @ts-ignore
                  Object.values(omit(this.parent, 'userId')).reduce(
                    // @ts-ignore
                    (acc, val) => acc + val,
                    0,
                  ) <= 100
                );
              })
              .required(),
          }),
          {},
        ),
        userId: Yup.number()
          .moreThan(0, 'User id should be positive')
          .required(),
      });
    }
    return Yup.mixed().notRequired();
  }),
});
