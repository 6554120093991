import React from 'react';
import {
  CircularProgress,
  LinearProgress,
  CircularProgressProps,
  LinearProgressProps,
} from '@material-ui/core';
import styled from 'styled-components';

export type SpinnerProps = LinearProgressProps &
  CircularProgressProps & {
    type?: 'circular' | 'linear';
  };

const DefSpinner = ({ type = 'circular', ...props }: SpinnerProps) => {
  if (type === 'circular') {
    return <CircularProgress {...(props as CircularProgressProps)} />;
  }

  if (type === 'linear') {
    return <LinearProgress {...(props as LinearProgressProps)} />;
  }

  return null;
};

const Spinner = styled(DefSpinner)`
  display: block;
  margin: 0 auto;
  align-self: center;
`;

export { Spinner };
