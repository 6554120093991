import React, { ComponentPropsWithRef } from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { visuallyHidden } from '../../styles';

export type TextProps = ComponentPropsWithRef<typeof Text>;

const Text = styled(Typography)<{
  component?: React.ElementType;
  $visuallyHidden?: boolean;
}>`
  ${({ $visuallyHidden: hidden }) => !!hidden && visuallyHidden}
`;

export { Text };
