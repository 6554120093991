import React from 'react';
import { AppProviders } from './providers';
import { RebalanceFormPage } from './pages';

const App = () => (
  <AppProviders>
    <RebalanceFormPage />
  </AppProviders>
);

export default App;
