/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { isNumber } from 'lodash-es';
import DefButton, {
  ButtonProps as DefButtonProps,
} from '@material-ui/core/Button';
import { Spinner as DefSpinner } from '../Spinner';

export type ButtonProps = DefButtonProps & ExtraProps;

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      loading = false,
      disabled,
      variant: defVariant,
      ...buttonProps
    },
    ref,
  ) => (
    <Wrapper className={className}>
      <StyledButton ref={ref} disabled={loading || disabled} {...buttonProps} />
      {loading && <Spinner size={24} />}
    </Wrapper>
  ),
);

type ExtraProps = {
  loading?: boolean;
  $buttonStyles?: ReturnType<typeof css>;
};

const Wrapper = styled.div`
  position: relative;
`;

const StyledButton = styled(
  forwardRef<HTMLButtonElement, Omit<ButtonProps, 'loading'>>(
    ({ $buttonStyles, ...rest }, ref) => <DefButton ref={ref} {...rest} />,
  ),
)<{
  $buttonStyles?: ExtraProps['$buttonStyles'];
}>`
  ${({ $buttonStyles }) => $buttonStyles};
`;

const Spinner = styled(DefSpinner)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: ${({ size }) => (isNumber(size) ? -size / 2 : 0)}px;
  margin-left: ${({ size }) => (isNumber(size) ? -size / 2 : 0)}px;
`;

export { Button };
