import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { getKrakenAssetPairs } from '../utils/kraken';

export const useGetKrakenItems = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [krakenNames, setKrakenNames] = useState<string[]>([]);

  useEffect(() => {
    let mounted = true;

    getKrakenAssetPairs()
      .then(({ data }) => {
        if (mounted) {
          setKrakenNames(data);
        }
      })
      .catch((e) => {
        enqueueSnackbar(`Couldn't load kraken pairs`, { variant: 'error' });
        console.log('error getting kraken pairs', e);
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {
      mounted = false;
    };
  }, [enqueueSnackbar]);

  return {
    loading,
    krakenNames,
  };
};
