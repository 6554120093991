import axios, { AxiosRequestConfig } from 'axios';

const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_API,
});

enum ApiPaths {
  KRAKEN_ASSET_PAIRS = '/kraken-asset-pairs',
  POST_REBALANACE_MESSAGE = '/post-rebalance',
}

type ApiCallResults = {
  krakenAssetPairs: string[];
  postBalancesMessage: void;
};

const apiCallConfigs: {
  [key in keyof ApiCallResults]: {
    config: AxiosRequestConfig;
    inputData?: unknown;
  };
} = {
  krakenAssetPairs: {
    config: {
      url: ApiPaths.KRAKEN_ASSET_PAIRS,
    },
  },
  postBalancesMessage: {
    config: {
      url: ApiPaths.POST_REBALANACE_MESSAGE,
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  },
};

export const callApi = <T extends keyof ApiCallResults>(
  type: T,
  data?: typeof apiCallConfigs[T]['inputData'],
) =>
  apiInstance.request<ApiCallResults[T]>({
    ...apiCallConfigs[type].config,
    data,
  });
