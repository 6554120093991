import { createMuiTheme } from '@material-ui/core';
import { DefaultTheme } from 'styled-components';
import { colors } from './colors';

const defTheme = createMuiTheme();

export const theme: DefaultTheme = {
  ...defTheme,
  palette: {
    ...defTheme.palette,
    colors,
    background: {
      default: colors.gray,
      paper: colors.white,
    },
  },
  props: {
    MuiTextField: {
      fullWidth: true,
      variant: 'outlined',
    },
    MuiButton: {
      variant: 'contained',
    },
  },
  getColor: (colorName) => colors[colorName],
};
