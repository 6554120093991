import React, { FC } from 'react';
import { SnackbarProvider } from 'notistack';
import { Styles } from './Styles';

const AppProviders: FC = ({ children }) => (
  <Styles>
    <SnackbarProvider
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      {children}
    </SnackbarProvider>
  </Styles>
);

export { AppProviders };
